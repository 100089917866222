
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
/* font-family: 'Roboto', sans-serif; */
  @import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@400;500&display=swap');
  /* font-family: 'Libre Franklin', sans-serif; */
.life-line{
    color: #FB4D46;
    text-shadow: 1px 1px 5px #94dd26; 
    text-shadow: 1px 1px 5px #ccff00; 
    font-size: 22px;
}
.left-navs{
    margin-left: 30px;
}
.right-navs{
    margin-right: 30px;
}
.center-navs{
    font-family: 'Roboto', sans-serif;
    text-transform: capitalize;
}
.center-navs h2{
  position: relative;
  left: 130px;
    font-size: 32px;
    color: #ff5100;
    animation: color-change 0.8s step-start infinite;
}
@keyframes color-change {
    20% {
      color: #d63031;
    }
    49% {
      color: #011307;
    } 
    100% {
      color: #FB4D46;
    }
  }
  /* Main-nav css starts here  */

  .main-nav{
    width: 100%;
    height: auto;
    padding: 15px 0px;
    background-color: #FB4D46;
    display: flex;
    justify-content: center;
  }
  .main-nav a{
    text-decoration: none;
    font-size: 19px;
    color: #fff;
    margin: 0 45px;
    font-family: 'Libre Franklin', sans-serif;
    text-transform: capitalize;
  }
 .main-nav a:hover{
  text-decoration: underline;
 }
.new-nav{
  display: none;
}
 /* media queries starts here */
 @media (max-width:1440px){
 .main-nav a{
  margin-left: 20px;
 }
 }

@media (max-width:1260px){
  .main-nav a{
    font-size: 18px;
    margin:0 30px;
  }
  .center-navs h2{
    left: 110px;
  }
}

@media (max-width:1185px){
.main-nav a{
  margin-left: 15px;
}
}

  @media (max-width:1069px){
    .center-navs h2{
      left: 50px;;
    }
   .hide a{
    display: none;
   }
   .new-nav{
    display: flex;
   width: 90%;
   justify-content: space-between;
   }
   .new-nav h2{
    /* margin-right: 30px; */
    font-family: Arial, Helvetica, sans-serif;
    text-transform: capitalize;
    position: relative;
    font-size: 32px;
    font-weight: 700;
    top: 15px;
    animation: color-cn 0.8s step-start infinite;
   }
   .new-nav .list-icon-nav{
    /* margin-left: 30px; */
    font-size: 40px;
    color: #fff;
   
   }
   .center-navs{
    display: none;
   }
  }
  @keyframes color-cn {
    10%{
      color:rgb(255, 213, 0);
    }
    50%{
      color: red;
    }
    100%{
      color: #fff;
    }
  }
  @media (max-width:520px){
    .new-nav h2{
      font-size: 25px;
    }
    .list-icon-nav {
      position: relative;
      top: -5px;
    }
  }
  @media (max-width:440px){
    .new-nav h2{
      font-size: 22px;
    }
  }
  @media (max-width:354px){
   #firttnav{
    justify-content: center !important;
   }
   .left-navs,.right-navs{
    margin: 0;
    padding: 0;
   }
  }
  .open{
   display: flex;
   width: 100%;
   flex-direction: column;
   justify-content: center;
  }
  .open a{
    display: inline;
    width: 70%;
  }
  .icon-pos{
    position: absolute;
    top: 50px;
    right: 20px;
  }
.hideee{
  display: none;
}

