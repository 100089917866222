.main-gal{
    width: 100%;
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-template-rows: 300px;
    justify-content: space-around;
    margin: 50px 0px;
    grid-gap: 50px;
    padding: 0 50px;
}
.main-gal img{
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 45px;
}
.img-gal-textt{
    width: 60%;
    margin: 0 auto;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: bolder;
    font-size: 45px;
    margin-top: 30px;
    color: #ee5253;
    border: 2px solid #ee5253;
    border-left-width: 10px;
    border-right-width: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

@media (max-width:1300px){
    .img-gal-textt{
        width: 70%;
    }
}

@media (max-width:1120px){
    .img-gal-textt{
        width: 80%;
    }
}
@media (max-width:990px){
    .main-gal{
        grid-template-columns: 45% 45%;
    }
    .img-gal-textt{
        width: 98%;
    }
}
@media (max-width:800px){
    .img-gal-textt{
        width: 95%;
        font-size: 35px;
    }
}
@media (max-width:650px){
    .main-gal{
        grid-template-columns: 90%;
        padding: 0;
    }
    .img-gal-textt{
        width: 90%;
        font-size: 25px;
    }
}
