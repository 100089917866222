
* a:hover{
    cursor: pointer;
}
.main-footer{
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FB4D46;
    position: relative;
}
.f1,.f2,.f3{
    width: 30%;
}
.f1 a{
    margin-top: 10px;
}
.f2{
    align-self: flex-start;
   
    margin-top: 35px;
}
.f2 a{
    text-decoration: none;
}
.f2 h3{
    text-transform: capitalize;
    text-align: center;
}
.f3{
    text-align: center;
}
.f2 li{
 list-style: none;
 color: #fff;
 font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
 font-weight: 400;
 margin-top: 25px;
 text-align: center;
}
.copywrite{
    font-size: 20px;
    text-align: center;
    background-color: #000;   
    color: #fff; 
    padding: 15px 0px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    text-transform: capitalize;
}
.f3{
    margin-right: 20px;
}


/* google maps css */


.f1{
  display: flex;
  flex-direction: column;
align-items: center;
}
.f1 a{
    color: #fff;
    text-align: center;
    text-decoration: none;
}

@media only screen and (max-width:779px){
    .main-footer{
        flex-direction: column;
        align-content: center;
        height: auto !important;
        padding: 25px 0px;
    }
    .f1,.f2,.f3{
        width: 70%;
       margin: 0 auto;
    }
   .f2{
    padding-top: 20px;
   }
}
