.main-carousel{
    width: 100%;
    height: 500px;
}
.main-carousel img{
    width: 100%;
    height: 500px;
    object-fit: fill;
}
.main-carousel .image2{
    object-fit: fill;
}
@media (max-width:990px){
    .main-carousel{
        height: 450px;
    }
    .main-carousel img{
        height: 450px;
    }
}
@media (max-width:790px){
    .main-carousel{
        height: 350px;
    }
    .main-carousel img{
        height: 350px;
    }
}
@media (max-width:590px){
    .main-carousel{
        height: 230px;
    }
    .main-carousel img{
        height: 230px;
    }
}
@media (max-width:390px){
    .main-carousel{
        height: 190px;
    }
    .main-carousel img{
        height: 190px;
    }
}