header{
    text-align: center;
    width:100%;
    padding: 2% 10%;
    background-color: #fff1d7;
}
header p{
    color: #192a56;
    line-height: 30px;
}
section{
    width:100%;
    padding: 25px 10px;
   font-family: sans-serif;
}
section h1{
    font-weight: bolder;
    text-decoration: underline;
    margin-bottom: 20px;
}
.tips{
    width: 70%;
    margin: 0 auto;
    text-align: center;
    padding: 15px;
    border-radius: 50px;
    color: #fff;
}
.t-r{
    background-color: red;
}
.t-g{
    margin-top: 15px;
    background-color: #218c74;
}
.t-b{
    margin-top: 15px;
    background-color: #1B9CFC;
}
.t-p{
    margin-top: 15px;
    background-color: #FC427B;
}
.t-l{
    margin-top: 15px;
    background-color: #2c2c54;
}
.posst{
    font-weight: bolder;
    font-size: calc(prev + 8)px;
}
