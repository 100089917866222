.evnt-1{
    width: 100%;
    padding: 10px 0px;
    text-align: center;
    
}
.evnt-1 img{
   width: 100%;
   object-fit: contain;
}
.ev-vid-1{
    width: 100%;
    text-align: center;
}
.upcoming-events{
    display: flex;
    justify-content: center;
    font-family:'Times New Roman', Times, serif;
    padding: 20px 0px;
}
.upcoming-events h1{
    font-weight: bolder;
    color: brown;
    text-decoration: underline;
}
.up-sec{
    display: flex;
    justify-content: center;
}
.e1,.e2{
    width: 500px;
    height: 500px;
}
.e1 img{
    width: 100%;
    height: 100%;
   object-fit: contain;
}
.e2 img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.previous h1{
  color: #000000;
}

@media (max-width:767px){
    .up-sec{
        flex-wrap: wrap;
    }
}