.whohari{
    background-color: #FFF1D7;
    width: 100%;
    padding: 40px;
}
.whohari h1{
    font-weight: bolder;
    color: #130f40;
    text-decoration: underline;
    margin-bottom: 40px;
}
.whohari p{
    padding: 0 50px;
}

@media (max-width:600px){
    .whohari p{
        padding: 0 10px;
    }
}