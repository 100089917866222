.mapouter {
    position: relative;
    text-align: right;
    width: 100%;
    height: 350px;
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    width: 100%;
    height: 350px;
}

.gmap_iframe {
    width: 100% !important;
    height: 350px !important;
}